

























































































import {Vue, Component} from 'vue-property-decorator';
import {DateTime} from 'luxon';
import {saveAs} from 'file-saver';
import ShipArrivalRepository from '@/api/repositories/ShipArrival.repository';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import ShipOwner from '@/models/ShipOwner.model';
import {shipOwnerStoreActions, shipOwnerStoreGetter} from '@/store/shipOwner.store';
import {namespace} from 'vuex-class';

const shipArrivalRepository: ShipArrivalRepository = RepositoryFactory.get('shipArrival');

const ShipOwnerStore = namespace('shipOwner');
const FILTER_VALUES = ['ship_Owner', 'vessel', 'contact_Information', 'draught', 'voyage_Nr',
  'eta', 'ets', 'planned_Eta', 'planned_Ets', 'desired_Difference', 'trade', 'last_Ports', 'next_Ports', 'remarks',
  'import_Volume', 'export_Volume', 'required', 'timestamp_First', 'timestamp_Last', 'slots'];

@Component
export default class SettingsReportView extends Vue {

  @ShipOwnerStore.Action(shipOwnerStoreActions.GET_ALL)
  public getAllShipOwners!: (payload?: { filterText: string }) => Promise<ShipOwner[]>;

  @ShipOwnerStore.Getter(shipOwnerStoreGetter.LIST_ALL)
  public _allShipOwners!: ShipOwner[];

  public selectedOwner: ShipOwner | null = null;

  private isZip: boolean = true;
  private sorting: 'asc' | 'desc' = 'asc'
  public zipMenu: boolean = false;
  public reportDates: string[] = [];
  public reportError: boolean = false;
  public reportInfo: { show: boolean, text: string } = {
    show: false,
    text: this.$t('SHIP_ARRIVAL.DOWNLOAD_INFO').toString()
  };
  public downloadType: 'PDF' | 'XLSX' = 'PDF';

  private filter: string[][] = [];

  private filterValues: string[] = [];

  private get downloadOptions() {
    const fileTypes: any = this.$t('GENERAL.FILE_TYPES');
    return Object.keys(fileTypes).map(type => {
      return {
        text: fileTypes[type],
        value: type
      }
    });
  }

  public mounted() {
    // prepare FILTER_VALUES for backend
    this.filterValues = FILTER_VALUES.map((value) => value.replaceAll('_', ''));
    this.getAllShipOwners();
    // For every value, that can be filtered, should be created one checkbox. So iterate over the filter values and
    // create an array of string arrays
    for (let i = 0; i < FILTER_VALUES.length;) {
      const props = [];
      // 3 checkboxes should be in one column
      for (let j = 0; j < 3; j++) {
        if (FILTER_VALUES[i]) {
          props.push(FILTER_VALUES[i]);
        }
        i++;
      }
      this.filter.push(props);
    }
  }

  get allShipOwners(): ShipOwner[] {
    return this._allShipOwners.filter(owner => owner.status);
  }

  public get getLocale() {
    if (this.$i18n.locale === 'en') {
      return 'en-US';
    } else {
      return 'de-DE';
    }
  }

  get formattedReportDates(): string {
    if (this.reportDates.length === 2) {
      this.reportError = false;
      // sort to be sure that the first entry is the startDate
      this.reportDates.sort((a, b) => a < b ? -1 : 0);
      return `${this.formatDate(this.reportDates[0])} - ${this.formatDate(this.reportDates[1])}`;
    } else return ''
  }

  private formatDate(date: string) {
    return DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('dd.MM.yyyy');
  }

  public clearReportDates() {
    this.reportDates = [];
  }

  public reportDatesChanged() {
    // @ts-ignore
    (this.$refs.zipMenu as any).save(this.reportDates);
  }

  public async downloadMany() {
    if (this.reportDates.length < 2) {
      // If the dates are invalid, show info and show the specific input
      this.reportError = true;
      return this.$notifyInfoSimplified('SHIP_ARRIVAL.REPORT_DATE_INFO');
    }
    this.reportInfo.show = true;
    let response;
    if (this.isZip) {
      response = await shipArrivalRepository.downloadMany({
        startDate: this.reportDates[0],
        endDate: this.reportDates[1],
        type: this.downloadType,
        shipOwner: this.selectedOwner ? this.selectedOwner.id! : '',
      });
    } else {
      response = await shipArrivalRepository.downloadCollection({
        startDate: this.reportDates[0],
        endDate: this.reportDates[1],
        shipOwner: this.selectedOwner ? this.selectedOwner.id! : '',
        sorting: this.sorting,
        locale: this.$i18n.locale,
        filter: this.filterValues.join(','),
      });
    }
    saveAs(response.data, `${this.formatDate(this.reportDates[0])}-${this.formatDate(this.reportDates[1])}` +
        `${this.selectedOwner ? '-' + this.selectedOwner.name : ''}.${this.isZip ? 'zip' : 'xlsx'}`)
    this.reportInfo.show = false;
  }
}
