










































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {SlotSettings} from "@/interfaces/SlotSettings.interface";
import {settingsStoreActions, settingsStoreGetter} from "@/store/settings.store";

const SettingsStore = namespace('settings');

@Component({})
export default class MiscSettingsView extends Vue {

  @SettingsStore.Action(settingsStoreActions.GET_SETTINGS)
  public getSettings!: () => Promise<SlotSettings>;

  @SettingsStore.Action(settingsStoreActions.UPDATE_SETTINGS)
  public updateSettings!: (payload: { settings: SlotSettings }) => Promise<SlotSettings>;

  @SettingsStore.Getter(settingsStoreGetter.SETTINGS)
  public settings!: SlotSettings;

  public isLoading: boolean = false;

  public async created() {
    await this.getSettings();
  }

  public async saveSettings() {
    if (this.settings.arrivalDeletionTime < 90 || this.settings.userDeletionTime < 90) {
      this.$notifyErrorSimplified('SETTINGS.SETTINGS_VIEW.MIN_TIME_ERROR');
      return;
    }
    try {
      await this.updateSettings({settings: this.settings});
      this.$notifySuccessSimplified('SETTINGS.SETTINGS_VIEW.SUCCESS');
    } catch (e) {
      this.$notifyErrorSimplified('SETTINGS.SETTINGS_VIEW.ERROR');
    }
  }
}

