














import {Component, Vue} from 'vue-property-decorator';
import SettingsSlotsView from './settings/SettingsSlots.view.vue';
import SettingsShipOwnersView from './settings/SettingsShipOwners.view.vue';
import SettingsVesselsView from './settings/SettingsVessels.view.vue';
import SettingsUsersView from './settings/SettingsUsers.view.vue';
import MiscSettingsView from './settings/MiscSettings.view.vue';
import {UserRole} from '@/models/User.model';
import SettingsImportShipArrivalsView from "@/views/settings/SettingsImportShipArrivals.view.vue";
import SettingsReportView from '@/views/settings/SettingsReport.view.vue';

export enum TabElements {
    SLOT_SETTINGS = 'SLOT_SETTINGS', MISC_SETTINGS = "MISC_SETTINGS", SHIP_OWNERS = 'SHIP_OWNERS', VESSELS = 'VESSELS', USERS = 'USERS', IMPORT_ARRIVALS = "IMPORT_ARRIVALS", REPORT = 'REPORT'
}

@Component({
    components: {
        SettingsSlotsView, SettingsShipOwnersView, SettingsVesselsView, SettingsUsersView, MiscSettingsView, SettingsImportShipArrivalsView, SettingsReportView
    }
})
export default class SettingsView extends Vue {

    public selectedTab: TabElements = TabElements.VESSELS;
    public filteredTabItems: { tab: TabElements, content: string, neededUserRoles: UserRole[] }[] = [];
    public tabItems: { tab: TabElements, content: string, neededUserRoles: UserRole[] }[] = [
        {
            tab: TabElements.MISC_SETTINGS,
            content: 'MiscSettingsView',
            neededUserRoles: [UserRole.IT_AUTOMOBILE, UserRole.KEY_USER]
        },
        {
            tab: TabElements.SLOT_SETTINGS,
            content: 'SettingsSlotsView',
            neededUserRoles: [UserRole.IT_AUTOMOBILE, UserRole.KEY_USER, UserRole.PLANER]
        },
        {
            tab: TabElements.USERS,
            content: 'SettingsUsersView',
            neededUserRoles: [UserRole.IT_AUTOMOBILE, UserRole.KEY_USER]
        },
        {
            tab: TabElements.SHIP_OWNERS,
            content: 'SettingsShipOwnersView',
            neededUserRoles: [UserRole.IT_AUTOMOBILE, UserRole.KEY_USER, UserRole.PLANER]
        },
        {
            tab: TabElements.VESSELS,
            content: 'SettingsVesselsView',
            neededUserRoles: [UserRole.IT_AUTOMOBILE, UserRole.KEY_USER, UserRole.PLANER]
        },
      {
        tab: TabElements.IMPORT_ARRIVALS,
        content: 'SettingsImportShipArrivalsView',
        neededUserRoles: [UserRole.IT_AUTOMOBILE, UserRole.KEY_USER, UserRole.PLANER]
      },
      {
        tab: TabElements.REPORT,
        content: 'SettingsReportView',
        neededUserRoles: [UserRole.IT_AUTOMOBILE, UserRole.KEY_USER, UserRole.PLANER]
      }];

    public mounted() {
        // Filter tabs regarding user roles
        this.filteredTabItems = this.tabItems.filter(tab => this.$hasRole(tab.neededUserRoles));
    }
}

