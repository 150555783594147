/**
 * Presents the slot amounts per weekday
 */

import {ShiftType} from '@/enums/ShiftType.enum';
import {SlotType} from '@/enums/SlotType.enum';

export enum WEEKDAY {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY'
}

export type SlotConfigInterface = {
    [key in WEEKDAY]: [SlotConfigEntryInterface]
}

export type SlotConfigEntryInterface = {
    id: string,
    shift: ShiftType,
    weekday: WEEKDAY,
    availableSlots: {
        [key in SlotType]: number
    }
}