



























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {userStoreActions, userStoreGetter, userStoreMutations} from '@/store/user.store';
import User, {UserRole} from '@/models/User.model';

const UserStore = namespace('user');

@Component({
  components: {
    UserTableComponent: () => import(
        /* webpackChunkName: "UserTableComponent" */
        '@/components/settings/UserTable.component.vue'),
    EditUserComponent: () => import(/* webpackChunkName: "EditUserComponent" */
        '@/components/settings/EditUser.component.vue')
  }
})
export default class SettingsUsersView extends Vue {

  @UserStore.Action(userStoreActions.GET_ALL)
  public getAllUsers!: () => Promise<User[]>;

  @UserStore.Getter(userStoreGetter.LIST_ALL)
  public allUsers!: User[];

  @UserStore.Mutation(userStoreMutations.SET_SELECTED_USER)
  public setSelectedUser!: (user: User | undefined) => void;

  @UserStore.Getter(userStoreGetter.SELECTED_USER)
  public selectedUser!: User;

  public searchValue: string = '';

  public showCreationDialog: boolean = false;

  public async created() {
    await this.getAllUsers();
  }

  public showCreateDialog() {
    this.setSelectedUser(undefined);
    this.showCreationDialog = true;
  }

  public async closeDialog(reload: boolean) {
    if (reload) {
      await this.getAllUsers();
    }

    this.setSelectedUser(undefined);
    this.showCreationDialog = false;
  }

  public get viewOnly(): boolean {
    return this.$hasRole(UserRole.KEY_USER)
  }
}

