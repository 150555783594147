











































import {Component, Vue} from 'vue-property-decorator';
import UploadRepository from '@/api/repositories/Upload.repository';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import {UserRole} from '@/models/User.model';
import ShipArrival from "@/models/ShipArrival.model";

const uploadRepository: UploadRepository = RepositoryFactory.get('upload');

@Component({
  components: {
    ShipArrivalTableComponent: () => import(
      /* webpackChunkName: "ShipArrivalTableComponent" */
      '@/components/shipArrival/ShipArrivalTable.component.vue'),
  }
})
export default class SettingsImportShipArrivalsView extends Vue {
  public selectedFile: File | null = null;

  public successImport: ShipArrival[] = [];
  public failedImport: ShipArrival[] = [];
  public errors: Array<{ message: string, code: number }> = [];

  public async uploadCSVClicked() {
    try {
      const uploadResponse = await uploadRepository.uploadShipArrivalCsv({file: this.selectedFile!});
      if (uploadResponse.data.imported) {
        this.successImport = ShipArrival.parseFromArray(uploadResponse.data.imported) as ShipArrival[];
      }
      if (uploadResponse.data.failed) {
        this.failedImport = ShipArrival.parseFromArray(uploadResponse.data.failed) as ShipArrival[];
      }
      if (uploadResponse.data.error) {
        this.errors = uploadResponse.data.error;
      }
      Vue.notifySuccessSimplified('SETTINGS.IMPORT_VIEW.NOTIFICATIONS.SAVED_DATA');
    } catch (e) {
      Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    } finally {
      console.log('finally')
    }
  }

  public openFileSelection() {
    (this.$refs.fileInput as HTMLElement).click();
  }

  public dragOver(event: Event) {
    event.preventDefault();
    (this.$refs.dropZone as HTMLElement).classList.add('dragEnter');
  }

  public dragLeave(event: Event) {
    event.preventDefault();
    (this.$refs.dropZone as HTMLElement).classList.remove('dragEnter');
  }

  public setSelectedFile() {
    this.selectedFile = (this.$refs.fileInput as HTMLInputElement).files![0];
  }

  public dropEvent(event: any) {
    event.preventDefault();
    (this.$refs.fileInput as HTMLInputElement).files = event.dataTransfer.files;
    if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      this.selectedFile = event.dataTransfer.files[0];
    }
    (this.$refs.dropZone as HTMLElement).classList.remove('dragEnter');
  }

  public get viewOnly(): boolean {
    return this.$hasRole(UserRole.PLANER)
  }
}

