

























































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {slotStoreActions, slotStoreGetter} from '@/store/slot.store';
import {SlotConfigInterface, WEEKDAY} from '@/interfaces/SlotConfig.interface';
import {ShiftType} from '@/enums/ShiftType.enum';
import SlotRepository from '@/api/repositories/Slot.repository';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import {UserRole} from '@/models/User.model';
import {DateTime} from 'luxon';

const SlotStore = namespace('slot');
const slotRepository: SlotRepository = RepositoryFactory.get('slot');

@Component({})
export default class SettingsSlotsView extends Vue {

  @SlotStore.Action(slotStoreActions.GET_SLOT_CONFIG)
  public getSlotConfig!: (payload: { calendarWeek: number, year: number }) => Promise<SlotConfigInterface>;

  @SlotStore.Getter(slotStoreGetter.SLOT_CONFIG)
  public slotConfig!: SlotConfigInterface;

  public weekdays: WEEKDAY[] = [WEEKDAY.MONDAY, WEEKDAY.TUESDAY, WEEKDAY.WEDNESDAY,
    WEEKDAY.THURSDAY, WEEKDAY.FRIDAY, WEEKDAY.SATURDAY, WEEKDAY.SUNDAY];
  public shifts: ShiftType[] = [ShiftType.FIRST, ShiftType.SECOND, ShiftType.THIRD];

  public dateMenu: boolean = false;
  public selectedDate: string = '';
  public isLoading: boolean = false;

  public async created() {
    const today = DateTime.utc();
    this.selectedDate = today.toISODate();
    await this.getSlotConfig({ calendarWeek: today.weekNumber, year: today.year });
  }

  public getDailySlotConfig(weekday: WEEKDAY) {
    return this.slotConfig[weekday];
  }

  public get getLocale() {
    if (this.$i18n.locale === 'en') {
      return 'en-US';
    } else  {
      return 'de-DE';
    }
  }

  public async dateFilterChanged() {
    // @ts-ignore
    (this.$refs.menu as any).save(this.selectedDate)
    const selectedDate = DateTime.fromFormat(this.selectedDate, 'yyyy-MM-dd');
    await this.getSlotConfig({ calendarWeek: selectedDate.weekNumber, year: selectedDate.year });
  }

  get formattedDate(): string {
    if (this.selectedDate) {
      const selectedDate = DateTime.fromFormat(this.selectedDate, 'yyyy-MM-dd');
      return `${this.$t('SETTINGS.SLOT_VIEW.WEEK')} ${selectedDate.weekNumber}`;
    } else return ''
  }

  public async saveAllConfigs() {
    const promises: any[] = [];

    try {
      Object.keys(this.slotConfig).forEach((weekday: string) => {
        const configObject = this.slotConfig[weekday as WEEKDAY];
        configObject.forEach(config => {
          promises.push(slotRepository.updateSlotConfig(config));
        })
      });
      await Promise.all(promises);

      this.$notifySuccessSimplified('SETTINGS.SLOT_VIEW.NOTIFICATIONS.SUCCESS');
    } catch (e) {
      this.$notifyErrorSimplified('SETTINGS.SLOT_VIEW.NOTIFICATIONS.ERROR');
    }

  }

  public get viewOnly(): boolean {
    return this.$hasRole(UserRole.PLANER);
  }

}

